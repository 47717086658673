<template>
  <div>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name in English"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userForm.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name in English"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name in Arabic"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userForm.name_ar"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name in Arabic"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Active"
            label-for="business_type"
          >
            <b-form-checkbox
              v-model="userForm.is_active"
              checked="true"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Logo"
            label-for="business_type"
          >
            <b-form-file
              accept=".jpg, .png, .gif"
              placeholder="Choose file"
              @change="onChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="border rounded p-1">
            <h4 class="mb-1">
              Logo
            </h4>
            <b-media
              no-body
              vertical-align="center"
            >
              <b-media-aside>
                <b-img
                  :src="preview"
                  height="200"
                  width="200"
                />
              </b-media-aside>
            </b-media>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addMerchant()"
          >
            Save
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  data() {
    return {
      id: this.$store.state.generalIds.id,
      loader: false,
      required,
      preview: null,
      image: null,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { formData, setFormData } = handleFormData()

    const userForm = ref({
      name: '',
      is_active: '',
      image: '',
      name_ar: '',
    })
    return {
      getValidationState,
      userForm,
      setFormData,
      formData,
    }
  },
  mounted() {
    this.viewMerchant()
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.userForm.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    closeModal() {
      this.userForm = {
        name: '',
        is_active: '',
        image: '',
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('merchantType')
    },
    addMerchant() {
      this.setFormData(this.userForm)
      if (this.id) {
        this.formData.append('_method', 'PUT')
        axios.put(`merchant-types/${this.id}`, this.formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.merchantType.getAllData()
            this.closeModal()
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
      } else {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.loader = true
            axios.post('merchant-types', this.formData).then(res => {
              if (res.status === 200 || res.status === 201) {
                this.$parent.$parent.$parent.$refs.merchantType.getAllData()
                this.closeModal()
                this.$toasted.show('Created Successfully', {
                  position: 'top-center',
                  duration: 3000,
                })
              }
            }).finally(() => {
              this.loader = false
            })
          }
        })
      }
    },
    viewMerchant() {
      if (this.id) {
        axios.get(`merchant-types/${this.id}`).then(res => {
          if (res.status === 200) {
            this.userForm = res.data?.data
          }
        })
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
