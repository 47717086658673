<template>
  <div>
    <general-table
      ref="merchantType"
      :api-url="APIURL"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :view-content="viewContent"
      guard="merchant_types"
    >
      <template #cell(name)="data">
        {{ data.item.name_ar }}
      </template>

      <template #cell(image)="data">
        <img
          width="48"
          :src="data.item.image"
          :alt="data.item.name"
        >
      </template>
    </general-table>
    <div>
      <b-modal
        id="merchantType"
        ref="genmodal"
        hide-footer
        centered
        no-close-on-backdrop
        title="Add Merchant Type"
      >
        <add-edit-merchant-type />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditMerchantType from './control-merchant-types/AddEditMerchantType.vue'

export default {
  components: { GeneralTable, AddEditMerchantType },
  data() {
    return {
      APIURL: 'merchant-types',
      addType: 'Add New Type',
      type: 'modal',
      viewContent: false,
      columns: [
        { key: 'select' },
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'image', label: 'Image' },
        { key: 'is_active', label: 'Active' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
}
</script>

<style>

</style>
